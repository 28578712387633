$primary-color: #0d77c4; 
$light-text: #ddd;
$dark-text: #222;
$dark-colored-text: rgb(0, 60, 100);
$light-bg: #fff;
$shaded-bg: rgb(250, 250, 255);
$dark-bg: rgb(0, 40, 66);
$light-sep: 1px solid rgb(220, 220, 220);
$dark-sep: 1px solid rgb(100, 100, 100);

@font-face {
    font-family: Lato;
    src: url(../fonts/Lato-Regular.ttf);
}

html {
    font-family: Lato, Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 28px;
    color: $dark-text;
}

body {
    padding: 0;
    margin: 0;
    background: $dark-bg;
}

.contact {
    background: $shaded-bg;
    border-bottom: $light-sep;
    text-align: center;
}

.contact ul {
    margin: auto;
    padding: 0;
    overflow: auto;
    list-style-type: none;
    display: inline-block;
}

.contact li {
    float: left;
    margin: 0;
    padding: 0;
}

.contact li a {
    display: block;
    text-align: center;
    text-decoration: none;
    margin: 4px 0;
    font-size: 14px;
    padding: 0 16px;
    color: $dark-text;
}

@media (max-device-width: 600px) {
    .contact li a {
        font-size: 10px;
    }
}

header {
    padding: 15px 0 15px 0;
    margin: 0;
    overflow: auto;
    background: $light-bg;
}

@media (max-device-width: 600px) {
    header {
        /*padding: 0; */
    }
}

.center {
    overflow: auto;
    max-width: 1000px;
    margin: auto;
    padding: 0;
}

.content {
    padding: 10px 0;
    margin: 0;
    background: $shaded-bg;
    border-top: $light-sep;
}

.light {
    background: $light-bg;
    border-top: $light-sep;
}

.content .center {
    padding: 0 30px;
}

.slice {
    display: flex;
}    

.light .slice {
    flex-direction: row-reverse;
}

.home {
    display: flex;
}

.home a {
    display: block;
    border: $dark-sep;
    text-decoration: none;
    color: $dark-text;
    width: 320px;
    margin: 5px;
    background: $light-bg;
}

.home .desc {
    padding: 0 20px;
    display: block;
}

.home img {
    width: 320px;
}

@media (max-device-width: 600px) {
    .home {
        flex-flow: column;
    }

    .home a {
        margin: 0 0 10px 0;
    }
}

.slice img {
    margin: 0 10px;
    width: 350px;
    align-self: center;
}

.slice video {
    margin: 0 10px;
    width: 500px;
    align-self: center;
}

.slice div {
    padding: 0 10px;
    margin: 0px;
}

@media (max-device-width: 600px) {
    .slice, .light .slice {
        flex-direction: column;
    }
}

@media (max-device-width: 600px) {
    .content .center {
        padding: 30px;
    }
}

.footer {
    border-top: $light-sep;
    height: 80px;
    color: #eee;
}

header img {
    float: left;
    width: 180px;
}

@media (max-device-width: 600px) {
    header img {
        float: none;
        display: block;
        width: 180px;
        margin: auto;
    }
}

header nav {
    text-align: center;
}

header nav ul {
    overflow: auto;
    margin: 14px auto 0 auto;
    padding: 0;
    list-style-type: none;
    display: inline-block;
    font-size: 16px;
    font-family: Lucida Console,Courier New, monospace;
/*    text-transform: uppercase; */
}

header nav li {
    float: left;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

header nav li a {
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 4px 8px;
    margin: 0px 8px;
    color: $dark-colored-text;
}

@media (max-device-width: 600px) {
    header nav ul {
        font-size: 10px;
        margin: 14px auto 0 auto;
    }
    header nav li a {
        margin: 0;
    }
}

header nav li a.current {
    border-bottom: 3px solid $primary-color;
}

.backdrop {
    background-size: 100%;
    background-position:top center;
    background-repeat: no-repeat;
    border-top: $dark-sep;
    border-bottom: $dark-sep;
    height: 250px;
}

@media (max-device-width: 600px) {
    .backdrop {
        height: 125px;
        background-size: 200%;
        font-size: 80%;
    }
}

.backdrop .headerbox {
    background: rgba(255, 255, 255, 0.9);
    color: $dark-text;
    margin: 0;
    padding: 0 10px;
    text-align: left;
    overflow: auto;
    border-bottom: $dark-sep;
}

.backdrop h1, .backdrop p {
    display: inline-block;
}

.backdrop p {
    margin-left: 20px;
}

.content p {
   /* max-width: 650px; */
}

.content h1 {
    font-size: 28px;
    margin: 0;
    padding: 7px;
}

.content h4 {
    font-size: 24px;
}

.footer {
    border-top: $light-sep;
    height: 80px;
    color: $light-text;
}

.footer {
    text-align: center;
}

.footer ul {
    overflow: auto;
    margin: 8px auto;   
    padding: 0;
    list-style-type: none;
    display: inline-block;
/*    max-width: 600px; */
}

.footer li {
    float: left;
    margin: 0;
    padding: 0;
}

.footer li a {
    display: block;
    text-align: center;
    text-decoration: none;
    color: $light-text;
    border-right: $dark-sep;
    margin: 8px 0;
    padding: 0 16px;
}

.footer li a.current {
    text-decoration: underline;
}

.footer li:last-child a {
    border-right: none;
}

@media (max-device-width: 600px) {
    .footer ul {
        font-size: 10px;
    }
    .footer li a {
        padding: 0 8px;
    }
}

hr {
    border: none;
    border-top: $light-sep;
}

.cta {
    background: $primary-color;
    color: $light-bg;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    font-weight: bold;
    margin: 20px 0;
    display: inline-block;
}

.center-text {
    text-align: center;
}

.footer span {
    color: #888;
    font-size: 14px;
}

#daq p {
    max-width: 600px;
}

.download-link dl {
   display: grid;
   grid-template-columns: 80px calc(100% - 80px);
   padding-left: 20px;
}

.download-link dl dt {
    padding-top: 10px;
}

.download-link {
    background: $light-bg;
    border: $dark-sep;
    text-align: center;
    margin: 20px auto;
    max-width: 600px;
    font-size: 20px;
}

.error {
    background: #fee;
    border: 1px solid #f00;
}

.download-link input {
    font-size: 20px;
    padding: 5px;
    border-radius: 10px;
    margin: 5px;
    width: 400px;
}

.lizenz {
    border: 1px solid #000;
    margin: 20px;
    float: left;
}

.lizenz td {
    text-align: center;
}

.lizenz th,td {
    background: #fff;
    padding: 0px;
}
